<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">选择城市</div>
      <div class="topRight"></div>
    </div>
    <div class="yy"></div>
    <div class="searBox">
      <img src="../../image/quanz/s.png" alt="" /><input
        type="text"
        placeholder="请输入城市名称"
      />
    </div>
    <div class="wzBox">
      <div><img src="../../image/quanz/dt.png" alt="" />不展示位置</div>
      <div><van-checkbox v-model="checked" checked-color="#0957C3"></van-checkbox></div>
    </div>
    <div class="dwBox">
      <div>当前定位</div>
      <div>
        <baidu-map @ready="getCurrentCity">
          <img src="../../image/quanz/dw.png" alt="" />{{
            currentCity
          }}</baidu-map
        >
      </div>
    </div>
    <van-index-bar >
      <van-index-anchor index="A" @change="onChange"/>
      <van-cell title="文本" />
      <van-cell title="文本" />
      <van-cell title="文本" />

      <van-index-anchor index="B" />
      <van-cell title="文本" />
      <van-cell title="文本" />
      <van-cell title="文本" />
      <van-index-anchor index="C" />
      <van-cell title="文本" />
      <van-cell title="文本" />
      <van-cell title="文本" />
      <van-index-anchor index="D" />
      <van-cell title="文本" />
      <van-cell title="文本" />
      <van-cell title="文本" />
      <van-index-anchor index="E" />
      <van-cell title="文本" />
      <van-cell title="文本" />
      <van-cell title="文本" />
      <van-index-anchor index="F" />
      <van-cell title="文本" />
      <van-cell title="文本" />
      <van-cell title="文本" />
    </van-index-bar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      checked: false,
      currentCity: "", //当前位置
      selectedOption: "请选择省份", // 存储所选选项的值
      options: [
        // 下拉框选项列表
        { id: 1, name: "option1" },
        { id: 2, name: "option2" },
        { id: 3, name: "option3" },
        { id: 4, name: "option1" },
      ],
      // flag: false,
    };
  },
  mounted() {},

  methods: {
    goBack() {
      this.$router.go(-1);
    },
    // 获取当前地理位置
    getCurrentCity({ BMap }) {
      const geolocation = new BMap.Geolocation();
      geolocation.getCurrentPosition((result) => {
        if (geolocation.getStatus() === 0) {
          const city = result.address.city;
          this.currentCity = city;
          this.showOverlay = false;
          console.log(city);
          this.$store.state.optionsList.forEach((item) => {
            item.children.forEach((i) => {
              if (i.name === city) {
                console.log(i.id);
                this.lookData.provinceId = item.id;
                this.lookData.cityId = item.id;
                console.log(item.id);
                this.gerinfoPage();
              }
            });
          });
        } else {
          this.currentCity = "定位失败";
        }
      });
    },
  },
};
</script>

<style scoped>
.boxTop {
  width: 750px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 18px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
}
.topRight {
  width: 48px;
  height: 48px;
  margin-right: 32px;
}
.yy {
  width: 750px;
  height: 20px;
  background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0.0001) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 0.0176;
  margin-top: 24px;
}
.searBox {
  width: 686px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-left: 32px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}
.searBox img {
  width: 32px;
  height: 32px;
  /* margin-left: 24px; */
  margin-right: 12px;
  display: flex;
  justify-content: center;
}
.searBox input {
  width: 200px;
  border: none;
  font-size: 24px;
  color: #97a4b4;
  background: #f6f8fa;
}
.wzBox {
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  border-bottom: 1px solid #f6f8fa;
}
.wzBox div:nth-child(1) {
  display: flex;
  align-items: center;
  margin-left: 32px;
}
.wzBox div:nth-child(1) img {
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
.wzBox div:nth-child(2) {
  margin-right: 32px;
}
.dwBox {
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dwBox div:nth-child(1) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-left: 32px;
}
.dwBox div:nth-child(2) img {
  width: 24px;
  height: 24px;
  margin-right: 4px;
}
.dwBox div:nth-child(2) {
  display: flex;
  align-items: center;
  font-size: 24px;

  margin-right: 32px;
}
/deep/.van-index-anchor {
  width: 750px;
  height: 42px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  line-height: 44px;
  font-size: 24px;
  color: #97a4b4;
}

</style>
